export default {
  uploading_collection_image: "We are uploading your collection image, it will be just a second!",
  uploading_product_images: "We are uploading your product images, it will be just a second!",
  uploading_products: "Now saving your products 🚀 ",
  uploading_content_images: "We are now uploading your images, it will be just a second!",
  uploading_content: "Now saving your store content 🚀 ",
  creating_store: "We started building your store 🏗️",
  uploading_store_images: "We started building your store 🏗️",
  finalizing_store_creation: "Now just polishing the details! 🏠",
}
