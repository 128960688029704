export default {
  product_admin: "Products and inventory",
  store_layout_admin: "Store layout and content",
  store_config_admin: "Store configuration",
  menu: "Menu",
  shopify: "Shopify products",
  shopify_message: "Products created on Shopify",
  imported: "Products",
  imported_message: "My products",
  finance: "Finance",
  menu_content: "Menu content",
  special_message_banner: "Special message banner",
  collection_banner: "Section banners",
  popular_products: "Popular products",
  highlighted_products: "Highlighted products",
  story_banner: "Story banner",
  important_message_bar: "Important message bar",
  footer_content: "Footer content",
  website_configuration: "Website configuration",
  translations: "Store translations",
  miscrosite_translations: "Microsites",
  style_config: "Website style configuration",
  splash_image_config: "Set splash image type",
  logo_upload: "Upload logo and brand image",
  shopify_config: "Shopify connection configuration",
  orders: "Orders",
  shipping_options_config: "Shipping and address configuration",
  rest_config: "Other configurations",
  project_config: "Store configuration",
  payment_gateway_config: "Payment options configuration",
  about_page: "About page configuration",
  discounts: "Discounts",
  social: "Marketing and social media",
  accounts: "Manage accounts",
  posts: "Social media posts",
  analytics: "Financial analytics",
  marketing_analytics: "Marketing analytics",
  advertising: "Ad management",
  inventory: "Stock and inventory management",
  legal_translations: "Other page translations",
}
