export default {
  manage_users: "Manage users",
  settings: "User settings",
  log_out: "Log out",
  profile: "My profile",
  user_actions: "User actions",
  user_data: "User data",
  change_password: "Change password",
  update_email: "Update email",
  users_under_management: "Users under management",
  add_user: "Add new user",
  edit_user: "Edit user permissions and managed users",
  user_permissions: "User permissions",
  update_your_information: "Update your information",
  delete_user: "Delete user",
  switch_project: "Switch store",
  support: "Support",
  send_message: "Send us a message",
  finalize_profile_setup: "Finalize profile setup",
  finalize_profile_message:
    "Please finalize your profile setup by filling out some important information.",
  show_guide: "Show store setup guide",
  no_users_created_yet: "No users have been created yet.",

  messages: {
    email_updated: "Your email address was successfully updated.",
    password_changed: "Your password was successfully changed.",
    passwords_unmatched: "Passwords do not match",
    invalid_email: "This is not a valid email address",
    users_over_limit: `You can not add more than {user_limit} users for this store.
    Upgrage your plan to allow more user accounts or contact support.`,
    new_user_created:
      "New user was successfully created and a password change request email was sent to their email address.",
    user_updated:
      "User permissions and users under management successfully updated",
    user_welcome: "Welcome {name}!",
    user_info_updated: "Your user information was successfully updated.",
    fill_out_value: "You need to fill out your {input}.",
    confirm_delete: "Are you sure you want to delete user account: {email} ?",
    user_deleted: "User successfully deleted.",
    user_added_to_project:
      "User was successfully added to your store and is now under your management.",
    message_send:
      "Your message was successfully sent. We will try to answer as soon as possible.",
    mobile_warning_message:
      "To get the best possible user experience with Ulsemo administration, <b>please use a tablet, laptop or desktop computer.</b>",
    same_email_error: "This email address is same as yours.",
  },

  inputs: {
    email: "E-mail address",
    password: "Password",
    name: "Name",
    password_again: "Password again",
    country: "Country of residence",
    language: "Preferred language",
    permissions: "User permissions",
    user_management: "Which users can user manage",
    phone: "Phone/mobile number",
    message: "Message",
    currency: "Preferred currency",
  },

  labels: {
    name: "User name",
    email: "Email",
  },

  permissions: {
    admin: "Grant Admin permissions",
    products: "Products sections",
    layout_and_content: "Store layout and content sections",
    configuration: "Store configuration sections",
    finance: "Finance sections",
    user: "User sections",
    translations: "Store translations",
    styling: "Store styling",
    shopify: "Shopify configuration",
    shipping: "Shipping and address configuration",
    payment_gateway: "Payment options configuration",
    project_configuration: "Store configuration",
    other_configuration: "Other configurations",
    orders: "Orders section",
    discounts: "Discounts sections",
    social: "Social media sections",
    accounts: "Social media accounts",
    permissions: "User management and permissions section",
    settings: "User settings section",
    module: "Can see section",
    manage: "Can manage section",
    email: "Can change own email",
    password: "Can change own password",
    posts: "Social media posts",
  },

  support_subjects: {
    title: "Subject",
    feature_request: "Feature request",
    bug_report: "Bug report",
    general_question: "General question",
  },

  support_errors: {
    subject_missing: "Please choose a subject of message.",
    empty_message: "Message can not be empty.",
    max_length: "Message can not be longer than 500 characters.",
  },

  store_settings: {
    title: "Store settings",
    delete_store: "Delete store",
    delete_store_message: `
      This action will permanently delete store: <b>{store_name}</b> on <b>{date}</b>, including its products shared through Ulsemo marketplace or Ulsemo cross selling service.
      As well as all order data, invoices and any other data stores within this store.
    `,
    deletion_steps:
      "To delete your store, first confirm this action by writing the store name as shown here:",
    deletion_label: "Store name",
    remove_user: "Also permanently remove my account.",
    remove_user_message:
      "Removes your account permanently from Ulsemo, and all stores you are assigned to.",
    remove_user_tooltip:
      "Only possible if you are not a store owner of any other stores.",
  },

  billing: {
    title: "Billing and invoicing",
    title_before_payment:
      "Welcome to the Final Step - Unlock Your Online Store!",
    message_before_payment: `Congratulations on successfully setting up your online store! You're just one step away from launching your incredible new business and enjoying a 10-day free trial of our feature-rich platform!.<br/><br/>
    By completing this final step and providing your payment details, you'll ensure a seamless experience once your trial ends, allowing you to continue growing your store without any interruptions. Plus, you'll get instant access to your beautifully designed store, ready to showcase your products and captivate your customers.<br/><br/>
    <b>Don't worry, you won't be charged until your 10-day free trial ends</b>, and you can always update your payment details or cancel your subscription at anytime.<br/><br/>
    Get ready to embark on an exciting journey of entrepreneurship and online success! We're thrilled to be part of your journey and can't wait to see your store thrive!`,
    payment_method_title: "Subscription",
    payment_method_message:
      "Here, you can set and manage your subscription as well as view and download invoices for Ulsemo services.",
    payment_method_button_add: "Subscribe to Ulsemo services",
    payment_method_button_change: "Enter Ulsemo subscription portal",
    subscription_message: `If you wish to set the subscription before the free trial end, you are free to do so.<br/>
    The subscription will start billing only after your free trial as ended, so you will not be charged anything until then.`,
    statuses: {
      subscribed: "You are subscribed.",
      unsubscribed: "You are currently not subscribed.",
      created: "Your subscription has been successfully payed and created!",
      canceled: "Your subscription has been successfully canceled.",
      error:
        "An error occurred when adding your subscription, please try again.",
    },
    submit_button: "Unlock My Store",
    back_button: "Back to admin",
  },
};
