export default {
  button: "Help",
  collection_banners: "What are Section Banners:",
  highlighted_products: "What are Highlighted Products:",
  product_creation: "How to create a Products and Collections:",
  menu_items: "How to edit your store Menu:",
  important_message_bar: "What is the Important Message Bar:",
  popular_products: "What are Popular Products:",
  special_message_banner: "What is the Special Message Banner:",
  footer_content: "How to manage your Footer Content:",
  story_banner: "What is the Story Banner:",
  style_config: "How to edit your Store Colors:",
}
