export default {
  title: "My online stores",
  project_name_undefined: "Undefined store name",
  domain_undefined: "Store domain undefined",
  create_new_store: "Create new store",
  missing_email: "Add your email address",
  first_store_title: "Your first Ulsemo store has been successfully created!<div>Start discovering what it has to offer:</div>",
  select_store_languages: "Store languages",
  visit_store: "Visit my store",
  visit_admin: "Go to store admin",

  messages: {
    project_name_required: "Store name is required.",
    project_created: "New store successfully created.",
    add_email_address: "We don't seem to have your email address on the record, please add one.",
    invalid_email: "Wrong email format (ie. my@email.com)",
    first_store_message: "Thank you for choosing Ulsemo services. We are here for you in case of any issues.",
    first_store_title: "Your first store is just around the corner!"
  },

  settings: {
    title: "Settings",
    delete_account: "Delete my account",
    delete_account_acknowledgment: "I acknowledge that this action will permanently delete my account and its connections to any Ulsemo store.",
    remove_user_tooltip: "Only possible if you are not the owner of any stores. First, you must delete all stores you are the owner of."
  },

  new_store: {
    title: "Your new store was successfully created!",
    message: `Your 10 day free trial starts now. <br/><br/>For your convenience, we have added a count down clock in the store admin bar,
    so you will be able to keep track of remaining trial time. <br/><br/>We will inform you 7 days and again 1 day, before the trial ends, so you have enough time to
    subscribe to the Ulsemo services and continue building your business.`,
    warning: `Once the free trial ends, your store will be still functional, but all the admin functions
    will be stopped until you subscribe to the Ulsemo services.`,
    open_store: "Go to new store",
    new_store_domain: {
      beginning: "You can view and use your store here:",
      end: "until you set your own domain."
    }
  }
}
