export default {
  divider: "OR",
  select_demo_images: "Select one of ours",
  next: "Next",
  skip: "Skip",
  back: "Back",
  skip_guide: "Skip guide",
  finish: "Finish",
  save: "Save",
  search: "Search",
  create_store: "Build my store!",
  step_1: {
    title: "Let's get started!<br>We will walk you through adding your first product.",
    create_product: "Create new product",
    select_demo_product: "Use demo product",
  },
  step_2: {
    title: "Give your product a title and a description.",
    product_title: "Product title",
    product_description: "Product description",
  },
  step_3: {
    title: "Add some images of your product.",
    add_image: "Add image",
  },
  step_4: {
    title: "Fill out your product's selling price and inventory.",
    product_price: "Product price",
    product_inventory: "Product inventory",
    product_inventory_tooltip: "How many products do you have in stock?",
    price_unit: "$",
    inventory_unit: "pcs",
  },
  step_5: {
    title: "Finally, create a collection/category to place your product in.",
    collection_name: "Collection name - e.g. T-shirts, Shoes, etc.",
    collection_image: "Add a collection image",
  },
  step_6: {
    title: "Now that you have a product, choose where you want it to be visible on your store.",
    add_product_to: "Add your product to",
    menu: "Menu",
    menu_tooltip: "Menu is the main navigation of your store. You can add links to products, collections, about page and others.",
    popular_products: "Popular items",
    popular_products_tooltip: "Popular items are displayed on the home page, so they are amongst the first things your customers see.",
    collection_banner: "Product & Collection banners",
    collection_banner_tooltip: "These banners are used to convey more information about a product or collection than just their picture.",
    highlighted_products: "Highlighted products",
    highlighted_products_tooltip: "This section is used to highlight products that you want to draw attention to and is placed at the bottom of the home page.",
    add_to_all: "Add to all",
    collection_banner_message: "This collection is completely amazing. You should definitely check it out.",
    product_banner_message: "This product is completely amazing. You should definitely check it out.",
    collection_banner_button: "Checkout our collection",
    product_banner_button: "Checkout our product",
    show_product: "Show product",
  },
  step_7: {
    title: "Finally, style your store using one of our pre-made pallets or select your own colors.",
    select_palette: "Select color palette",
    create_custom_palette: "Select custom colors",
    add_logo: "Add logo",
    select_pre_made_palette: "Select pre-made palette",
    change_logo: "Change logo",
  },
  step_8: {
    title: "All that is left is naming your store and selecting its languages.",
    store_name: "Store name",
    store_languages: "Store languages",
    skip_title: "Let's skip all the jibber jabber and create your store. Just select store name and languages.",
  },

  demo_product: {
    title: "Street style t-shirt",
    description: "This is a t-shirt with street style design. It is made of 100% cotton and is very comfortable to wear.",
  },

  demo_collection: {
    title: "T-shirts",
  },
}
